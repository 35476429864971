import iconBtc from '../assets/currency/btc-icon.svg'
import iconEth from '../assets/currency/eth-icon.svg'
import iconAda from '../assets/currency/ada-icon.svg'
import iconDoge from '../assets/currency/doge-icon.svg'
import iconXrp from '../assets/currency/xrp-icon.svg'
import { roundDecimal } from '../lib/number'

export const listedCoins = [
    {
        name: 'Bitcoin',
        paprikaId: 'btc-bitcoin',
        krakenId: 'XBT',
        krakenSymbol: 'XXBTZEUR',
        krakenPair: 'XBTEUR',
        symbol: 'BTC',
        logo: iconBtc,
        orderMin: 0.0002,
        color: {
            start: '#F7CE68',
            end: '#FBAB7E',
        }
    },
    {
        name: 'Ethereum',
        symbol: 'ETH',
        krakenId: 'ETH',
        paprikaId: 'eth-ethereum',
        krakenSymbol: 'XETHZEUR',
        krakenPair: 'ETHEUR',
        logo: iconEth,
        orderMin: 0.004,
        color: {
            start: '#59C8F7',
            end: '#503BDA',
        }
    },
    {
        name: 'XRP',
        symbol: 'XRP',
        krakenId: 'XRP',
        paprikaId: 'xrp-xrp',
        krakenSymbol: 'XXRPZEUR',
        krakenPair: 'XRPEUR',
        logo: iconXrp,
        orderMin: 5,
        color: {
            start: '#000000',
            end: '#828181',
        }
    },
    {
        name: 'Dogecoin',
        symbol: 'DOGE',
        paprikaId: 'doge-dogecoin',
        krakenSymbol: 'XDGEUR',
        krakenId: 'XDG',
        krakenPair: 'XDGEUR',
        logo: iconDoge,
        orderMin: 50,
        color: {
            start: '#FFCF86',
            end: '#FF9900',
        }
    },
    {
        name: 'Cardano',
        symbol: 'ADA',
        paprikaId: 'ada-cardano',
        krakenSymbol: 'ADAEUR',
        krakenId: 'ADA',
        krakenPair: 'ADAEUR',
        logo: iconAda,
        orderMin: 5,
        color: {
            start: '#37ECBA',
            end: '#72AFD3',
        }
    },
]


export function selectCoin(coinSymbol) {
    let result = listedCoins.filter(coin => coin.symbol === coinSymbol)
    return result[0]
}

export function extractTickers(json) {
    var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
    tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
    return tickers
}

export function createTicker(key, value) {
    return {
        assetPair: assetPair(key),
        // TODO rounde decimal attention a regarder
        price: roundDecimal(value.c[0], 2)
    }
}

export function assetPair(s) {
    return s.replace(/[XZ]([A-Z]{3})[XZ]([A-Z]{3})/g, '$1/$2')
}

export function getPriceOfCoin(coin) {
    let coinvalue = 0;
    return fetch(`https://api.kraken.com/0/public/Ticker?pair=${coin.krakenId}EUR`)
    .then(response => response.json())
    .then(data => {
        coinvalue = extractTickers(data)
        return coinvalue[0]
    });
}