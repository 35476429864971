import React, { useEffect, useState } from 'react'
import './EditPersonalInfoComponent.css'
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SignOutComponent from '../SignOutComponent/SignOutComponent';
import { notifyError, notifyValidate } from '../../lib/notification';

const EditPersonalInfoComponent = () => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [currentKyc, setcurrentKyc] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState({
        firstname: '',
        lastname: '',
        birthdate: '',
        address: '',
        phone: '',
        postal_code: '',
        country: '',
        city: '',
    });

    useEffect(() => {
        async function getKyc() {
            const currentU = Parse.User.current();
            if (currentU) {
                const Kyc = Parse.Object.extend("Kyc");
                const query = new Parse.Query(Kyc);
                query.equalTo("objectId", currentU.get('kyc').id);
                const subscribe = await query.subscribe();
                let kyc = {}
                subscribe.on('open', async () => {
                    kyc = await query.find()
                    setCurrentUser({
                        lastname: currentU.get('lastname'),
                        firstname: currentU.get('username'),
                        birthdate: kyc[0].get('birthdate'),
                        address: kyc[0].get('address'),
                        phonenumber: kyc[0].get('phonenumber'),
                        postal_code: kyc[0].get('postal_code'),
                        country: kyc[0].get('country'),
                        city: kyc[0].get('city'),
                    });
                    setcurrentKyc(kyc[0]);
                    setIsLoading(false)
                })
                subscribe.on('update', (kyc) => {
                    setCurrentUser({
                        lastname: currentU.get('lastname'),
                        firstname: currentU.get('username'),
                        birthdate: kyc.get('birthdate'),
                        address: kyc.get('address'),
                        phonenumber: kyc.get('phonenumber'),
                        postal_code: kyc.get('postal_code'),
                        country: kyc.get('country'),
                        city: kyc.get('city'),
                    });
                    setcurrentKyc(kyc);
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            }
        }
        getKyc()
    }, [])


    function onChangeinfo(e, info) {
        setCurrentUser({
            [info]: e.target.value,
        })
    }

    const onSubmit = (d) => {
        currentKyc.set('address', d.address);
        currentKyc.set('phonenumber', d.phonenumber);
        currentKyc.set('postal_code', d.postal_code);
        currentKyc.set('country', d.country);
        currentKyc.set('city', d.city);
        currentKyc.save().then(() => {
            notifyValidate('Vos informations ont bien été modifié')
        }).catch(error => {
            notifyError('Une erreur est survenue')
        });
    }

    return (
        <>
            {
                isLoading ? <LoadingComponent /> :
                    <form onSubmit={handleSubmit(onSubmit)} className='information-container'>
                        <div className="flex justify-space">
                            <div style={{ width: '45%'}}>
                                <p className='info-description'>Prénom</p>
                                <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                                    <input value={currentUser.firstname} {...register("firstname", { required: true })} placeholder='Prénom' className='input-module' />
                                    {errors.firstname && "Le numero de telephone est obligatoire"}
                                </div>
                            </div>
                            <div style={{ width: '45%'}}>
                                <p className='info-description'>Nom</p>
                                <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                                    <input value={currentUser.lastname}  {...register("lastname", { required: true })} placeholder='Nom' className='input-module' />
                                    {errors.lastname && "Le numero de telephone est obligatoire"}
                                </div>
                            </div>
                        </div>
                        <p className='info-description'>Date de naissance</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentUser.birthdate} {...register("birthdate", { required: true })} placeholder='Date de naissance' className='input-module' />
                            {errors.date && "La date de naissance est obligatoire"}
                        </div>
                        <p className='info-description'>Address</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input {...register("address", { required: true })} value={currentUser.address} placeholder='Adresse' className='input-module' onChange={(e) => {onChangeinfo(e, 'address')}}/>
                            {errors.address && "Adresse est obligatoire"}
                        </div>
                        <p className='info-description'>Téléphone</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentUser.phonenumber || ''} {...register("phonenumber", { required: true })} placeholder='Téléphone' className='input-module' onChange={(e) => {onChangeinfo(e, 'phonenumber')}}/>
                            {errors.phonenumber && "Le numero de telephone est obligatoire"}
                        </div>
                        <p className='info-description'>Code postal</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input  value={currentUser.postal_code || ''} {...register("postal_code", { required: true })} placeholder='Code postal' className='input-module' onChange={(e) => {onChangeinfo(e, 'postal_code')}} />
                            {errors.postal_code && "Le code postal est obligatoire"}
                        </div>
                        <p className='info-description'>Pays</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentUser.country || ''} {...register("country", { required: true })} placeholder='Pays' className='input-module' onChange={(e) => {onChangeinfo(e, 'country')}} />
                            {errors.country && "Le pays est obligatoire"}
                        </div>
                        <p className='info-description'>Ville</p>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentUser.city || ''} {...register("city", { required: true })} placeholder='Ville' className='input-module'  onChange={(e) => {onChangeinfo(e, 'city')}} />
                            {errors.city && "La ville est obligatoire"}
                        </div>
                        <div className='flex justify-space'>
                            <button name="button_submit" style={{ marginTop: '3rem' }} className='custom-btn'  type='submit' >Mettre à jour</button>
                            <SignOutComponent></SignOutComponent>
                        </div>
                    </form>
            }
        </>
    )
}

export default EditPersonalInfoComponent